import { encrypt,decrypt } from '~/assets/js/encryption'

export const strict = false
export const actions = {
  async nuxtServerInit ({ commit } ) {
    let phoneNumber = this.$cookies.get('auth')
    if (phoneNumber) {
      await this.$axios
        .post('/validate', {
          phone_number: decrypt(phoneNumber).replace("+66","0"),
          country_code: '66'
        })
        .then(async(res) => {
          if (res.status === 200){
            await this.$fire.auth.signInWithCustomToken(res.data.data.custom_token)
            this.$cookies.set('auth', encrypt(this.$fire.auth.currentUser.phoneNumber) , {
              maxAge: 60 * 60 * 24 * 30,
              path: '/'
            })
            commit('authenticated/login')
          }
        })
    }
    let items = this.$cookies.get('cart')
    if (items) {
      commit('cart/setItems', JSON.parse(decrypt(items)))
    }
  }
}
