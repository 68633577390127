export default function ({ $config: { env }, store, $axios, $fire }) {
  if (env === "dev") {
    $axios.setBaseURL("https://api-go-dev.younghappy.com");
  } else if (env === "prod") {
    $axios.setBaseURL("https://api-go.younghappy.com");
  }
  $axios.onRequest((config) => {
    if (store.state.authenticated.authenticated) {
      $fire.auth.currentUser.getIdToken(false).then((token) => {
        config.headers.common["Authorization"] = "Bearer " + token;
      });
    }
  });
}
