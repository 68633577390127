<template>
  
</template>

<script>
export default {
  methods: {
    start () {
      this.$store.dispatch('app/startLoading')
    },
    finish () {
      this.$store.dispatch('app/finishLoading')
    }
  }
}
</script>