var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{staticClass:"app",attrs:{"dark":""}},[_c('v-navigation-drawer',{attrs:{"clipped":true,"height":"100%","app":"","fixed":"","temporary":""},scopedSlots:_vm._u([(_vm.$store.state.authenticated.authenticated === true)?{key:"append",fn:function(){return [_c('div',{staticClass:"pa-4"},[_c('span',[_vm._v("ติดต่อยังแฮปปี้")])]),_vm._v(" "),_c('div',{staticClass:"d-flex flex-row justify-space-around"},[_c('a',{attrs:{"href":"https://www.facebook.com/YoungHappySE/"}},[_c('img',{staticStyle:{"width":"40px"},attrs:{"src":require('../assets/facebook.svg')}})]),_vm._v(" "),_c('a',{attrs:{"href":"https://line.me/R/ti/p/@younghappy"}},[_c('img',{staticStyle:{"width":"40px"},attrs:{"src":require('../assets/line.svg')}})]),_vm._v(" "),_c('a',{attrs:{"href":"https://www.youtube.com/channel/UC5suOg0XFqsjMdomk4S-AyQ"}},[_c('img',{staticStyle:{"width":"40px"},attrs:{"src":require('../assets/youtube.svg')}})]),_vm._v(" "),_c('a',{attrs:{"href":"https://www.instagram.com/younghappyse/"}},[_c('img',{staticStyle:{"width":"40px"},attrs:{"src":require('../assets/instagram-sketched.svg')}})])]),_vm._v(" "),_c('div',{staticClass:"pa-4 mb-4"},[_c('v-btn',{attrs:{"color":"error","block":""},on:{"click":function($event){return _vm.logout()}}},[_vm._v("ออกจากระบบ")])],1)]},proxy:true}:null],null,true),model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',[_vm._l((_vm.events),function(item){return _c('v-list-item',{key:item.title,attrs:{"to":item.to,"color":"primary","router":"","exact":""},on:{"click":function($event){_vm.$store.dispatch('app/savePreviousPath', _vm.$route.fullPath);
          _vm.drawer = false;}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_vm._v(" "),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.title)}})],1)],1)}),_vm._v(" "),_c('v-list-group',{attrs:{"no-action":"","prepend-icon":"mdi-cart"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("สินค้ายังแฮปปี้")])],1)]},proxy:true}])},[_vm._v(" "),_vm._l((_vm.shop),function(item){return _c('v-list-item',{key:item.title,attrs:{"to":item.to,"color":"primary","router":"","exact":""},on:{"click":function($event){_vm.$store.dispatch('app/savePreviousPath', _vm.$route.fullPath);
            _vm.drawer = false;}}},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.title)}})],1)],1)})],2),_vm._v(" "),_vm._l((_vm.mics),function(item){return _c('v-list-item',{key:item.title,attrs:{"href":item.to === '' ? item.href : '',"target":item.to === '' ? '_blank' : '_self',"color":"primary","router":"","exact":""},on:{"click":function($event){return _vm.handleDrawer(item)}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_vm._v(" "),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.title)}})],1)],1)})],2)],1),_vm._v(" "),_c('v-app-bar',{attrs:{"clipped-left":true,"fixed":"","app":""}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}),_vm._v(" "),_c('router-link',{attrs:{"to":"/"}},[_c('v-img',{attrs:{"src":"/app_bar_logo.png","max-height":"3.15rem","max-width":"160","contain":""}})],1),_vm._v(" "),_c('v-spacer'),_vm._v(" "),(
        _vm.$store.state.authenticated.authenticated === false &&
          _vm.getBasePath !== 'account'
      )?_c('v-btn',{staticClass:"font-caption",attrs:{"color":"primary","to":'/account/login'},on:{"click":function($event){return _vm.$store.dispatch('app/savePreviousPath', _vm.$route.fullPath)}}},[_vm._v("\n      ลงชื่อเข้าใช้\n    ")]):(
        _vm.$store.state.authenticated.authenticated === true &&
          _vm.getBasePath !== 'account' &&
          _vm.getBasePath !== 'shop'
      )?_c('v-btn',{staticClass:"font-caption",attrs:{"color":"primary","outlined":"","to":'/account'}},[_vm._v("\n      กิจกรรมของฉัน\n    ")]):_vm._e()],1),_vm._v(" "),_c('v-main',[_c('v-container',{staticClass:"pa-0"},[_c('nuxt')],1)],1),_vm._v(" "),_c('v-overlay',{attrs:{"value":_vm.$store.state.app.loading,"z-index":"999"}},[_c('v-progress-circular',{attrs:{"color":"primary_lighten","size":70,"width":7,"indeterminate":""}})],1),_vm._v(" "),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.qrDialog),callback:function ($$v) {_vm.qrDialog=$$v},expression:"qrDialog"}},[_c('v-card',[_c('v-img',{attrs:{"src":_vm.qrSrc,"alt":"my qr code"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }