import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _079375d0 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _4fa40bfd = () => interopDefault(import('../pages/event/index.vue' /* webpackChunkName: "pages/event/index" */))
const _8a643a6e = () => interopDefault(import('../pages/shop/index.vue' /* webpackChunkName: "pages/shop/index" */))
const _0ef25337 = () => interopDefault(import('../pages/account/callback.vue' /* webpackChunkName: "pages/account/callback" */))
const _5d89ce32 = () => interopDefault(import('../pages/account/login.vue' /* webpackChunkName: "pages/account/login" */))
const _2a880295 = () => interopDefault(import('../pages/account/register.vue' /* webpackChunkName: "pages/account/register" */))
const _a1610f8a = () => interopDefault(import('../pages/account/update.vue' /* webpackChunkName: "pages/account/update" */))
const _1b93cba4 = () => interopDefault(import('../pages/account/verify/index.vue' /* webpackChunkName: "pages/account/verify/index" */))
const _3d308336 = () => interopDefault(import('../pages/event/detail.vue' /* webpackChunkName: "pages/event/detail" */))
const _63ce550e = () => interopDefault(import('../pages/shop/cart.vue' /* webpackChunkName: "pages/shop/cart" */))
const _13e4a3ec = () => interopDefault(import('../pages/shop/item.vue' /* webpackChunkName: "pages/shop/item" */))
const _4417a395 = () => interopDefault(import('../pages/shop/transaction.vue' /* webpackChunkName: "pages/shop/transaction" */))
const _511b6fcc = () => interopDefault(import('../pages/account/verify/manual.vue' /* webpackChunkName: "pages/account/verify/manual" */))
const _e542f99c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _079375d0,
    name: "account"
  }, {
    path: "/event",
    component: _4fa40bfd,
    name: "event"
  }, {
    path: "/shop",
    component: _8a643a6e,
    name: "shop"
  }, {
    path: "/account/callback",
    component: _0ef25337,
    name: "account-callback"
  }, {
    path: "/account/login",
    component: _5d89ce32,
    name: "account-login"
  }, {
    path: "/account/register",
    component: _2a880295,
    name: "account-register"
  }, {
    path: "/account/update",
    component: _a1610f8a,
    name: "account-update"
  }, {
    path: "/account/verify",
    component: _1b93cba4,
    name: "account-verify"
  }, {
    path: "/event/detail",
    component: _3d308336,
    name: "event-detail"
  }, {
    path: "/shop/cart",
    component: _63ce550e,
    name: "shop-cart"
  }, {
    path: "/shop/item",
    component: _13e4a3ec,
    name: "shop-item"
  }, {
    path: "/shop/transaction",
    component: _4417a395,
    name: "shop-transaction"
  }, {
    path: "/account/verify/manual",
    component: _511b6fcc,
    name: "account-verify-manual"
  }, {
    path: "/",
    component: _e542f99c,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
