import { encrypt } from '~/assets/js/encryption'

export const state = () => ({
  items: [],
})

export const mutations = {
  addItem(state, item) {
    state.items.push(item)
  },
  setItems(state, items) {
    state.items = items
  },
  removeItem(state, item) {
    var index = state.items.indexOf(item);
    if (index !== -1) state.items.splice(index, 1);
  },
  increaseAmount(state, target) {
    state.items.filter((item) => {
      return item.product_id == target.product_id
    })[0].amount++
  },
  decreaseAmount(state, target) {
    state.items.filter((item) => {
      return item.product_id == target.product_id
    })[0].amount--
  },
  clearCart(state) {
    state.items = []
  }
}

export const actions = {
  addItem({commit, state}, item ) {
    var existing = state.items.filter((i) => { return i.post_id === item.post_id })[0]
    if (!existing)
        commit('addItem', item)
    else
      existing.amount += item.amount

    this.$cookies.set(
      'cart',
      encrypt(JSON.stringify(state.items)),
      {
        maxAge: 60 * 60 * 24 * 30,
        path: '/'
      }
    )
  },
  removeItem({ commit, state }, item) {
    commit('removeItem', item)
    this.$cookies.set(
      'cart',
      encrypt(JSON.stringify(state.items)),
      {
        maxAge: 60 * 60 * 24 * 30,
        path: '/'
      }
    )
  },
  increaseAmount({ commit, state }, item) {
    commit('increaseAmount', item)
    this.$cookies.set(
      'cart',
      encrypt(JSON.stringify(state.items)),
      {
        maxAge: 60 * 60 * 24 * 30,
        path: '/'
      }
    )
  },
  decreaseAmount({ commit, state }, item) {
    commit('decreaseAmount', item)
    this.$cookies.set(
      'cart',
      encrypt(JSON.stringify(state.items)),
      {
        maxAge: 60 * 60 * 24 * 30,
        path: '/'
      }
    )
  },
  clearCart({ commit }) {
    commit('clearCart')
    this.$cookies.remove('cart')
  }
}