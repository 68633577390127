export const state = () => ({
  previousPath: '',
  loading: false,
  isRegisterState: false
})

export const mutations = {
  savePreviousPath(state, path) {
    state.previousPath = path
  },
  clearPreviousPath(state) {
    state.previousPath = ''
  },
  setLoading(state, loading) {
    state.loading = loading
  },
  setIsRegisterState(state, isRegisterState) {
    state.isRegisterState = isRegisterState
  }
}

export const actions = {
  savePreviousPath({ commit }, path) {
    commit('savePreviousPath', path)
  },
  clearPreviousPath({ commit }) {
    commit('clearPreviousPath')
  },
  startLoading({ commit }) {
    commit('setLoading', true)
  },
  finishLoading({ commit }) {
    setTimeout(() => {
      commit('setLoading', false)
    },500);
  },
  finishLoadingImmediately({ commit }) {
    commit('setLoading', false)
  },
  setIsRegisterState({commit}, isRegisterState) {
    commit('setIsRegisterState', isRegisterState)
  }
}
