export const state = () => ({
  available: [],
  events: [],
  volunteers: [],
  passed: [],
})

export const mutations = {
  setAvailable(state, events) {
    state.available = events
  },
  setEvents(state, events) {
    state.events = events
  },
  setVolunteers(state, events) {
    state.volunteers = events
  },
  setPassed(state, events){
    state.passed = events
  },
  concatPassed(state, events){
    state.passed = state.passed.concat(events)
  }
}
