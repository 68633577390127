<template>
  <v-app dark class="app">
    <v-navigation-drawer
      v-model="drawer"
      :clipped="true"
      height="100%"
      app
      fixed
      temporary
    >
      <v-list>
        <v-list-item
          v-for="item in events"
          :key="item.title"
          :to="item.to"
          color="primary"
          router
          exact
          @click="
            $store.dispatch('app/savePreviousPath', $route.fullPath);
            drawer = false;
          "
        >
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title v-text="item.title" />
          </v-list-item-content>
        </v-list-item>
        <v-list-group no-action prepend-icon="mdi-cart">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>สินค้ายังแฮปปี้</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
            v-for="item in shop"
            :key="item.title"
            :to="item.to"
            color="primary"
            router
            exact
            @click="
              $store.dispatch('app/savePreviousPath', $route.fullPath);
              drawer = false;
            "
          >
            <v-list-item-content>
              <v-list-item-title v-text="item.title" />
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-item
          v-for="item in mics"
          :key="item.title"
          :href="item.to === '' ? item.href : ''"
          :target="item.to === '' ? '_blank' : '_self'"
          color="primary"
          router
          exact
          @click="handleDrawer(item)"
        >
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title v-text="item.title" />
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template
        v-slot:append
        v-if="$store.state.authenticated.authenticated === true"
      >
        <div class="pa-4 ">
          <span>ติดต่อยังแฮปปี้</span>
        </div>
        <div class="d-flex flex-row justify-space-around">
          <a href="https://www.facebook.com/YoungHappySE/"
            ><img style="width: 40px" :src="require('../assets/facebook.svg')"
          /></a>
          <a href="https://line.me/R/ti/p/@younghappy"
            ><img style="width: 40px" :src="require('../assets/line.svg')"
          /></a>
          <a href="https://www.youtube.com/channel/UC5suOg0XFqsjMdomk4S-AyQ"
            ><img style="width: 40px" :src="require('../assets/youtube.svg')"
          /></a>
          <a href="https://www.instagram.com/younghappyse/"
            ><img
              style="width: 40px"
              :src="require('../assets/instagram-sketched.svg')"
          /></a>
          <!-- <a href="www.google.com"><div class="lineicons">.</div></a> -->
          <!-- <a href="https://www.facebook.com/YoungHappySE/"><v-icon>mdi-facebook</v-icon></a> -->
          <!-- <a  href="https://line.me/R/ti/p/@younghappy"><v-icon>mdi-google-hangouts</v-icon></a> -->
          <!-- <a href="https://www.youtube.com/channel/UC5suOg0XFqsjMdomk4S-AyQ"><v-icon>mdi-youtube</v-icon></a> -->
          <!-- <a href="https://www.instagram.com/younghappyse/"><v-icon>mdi-instagram</v-icon></a> -->
        </div>
        <div class="pa-4 mb-4">
          <v-btn color="error" block @click="logout()">ออกจากระบบ</v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    <v-app-bar :clipped-left="true" fixed app>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <router-link to="/">
        <v-img
          src="/app_bar_logo.png"
          max-height="3.15rem"
          max-width="160"
          contain
        />
      </router-link>
      <v-spacer />
      <v-btn
        v-if="
          $store.state.authenticated.authenticated === false &&
            getBasePath !== 'account'
        "
        class="font-caption"
        color="primary"
        :to="'/account/login'"
        @click="$store.dispatch('app/savePreviousPath', $route.fullPath)"
      >
        ลงชื่อเข้าใช้
      </v-btn>
      <v-btn
        v-else-if="
          $store.state.authenticated.authenticated === true &&
            getBasePath !== 'account' &&
            getBasePath !== 'shop'
        "
        class="font-caption"
        color="primary"
        outlined
        :to="'/account'"
      >
        กิจกรรมของฉัน
      </v-btn>
    </v-app-bar>
    <v-main>
      <v-container class="pa-0">
        <nuxt />
      </v-container>
    </v-main>
    <v-overlay :value="$store.state.app.loading" z-index="999">
      <v-progress-circular
        color="primary_lighten"
        :size="70"
        :width="7"
        indeterminate
      />
    </v-overlay>
    <v-dialog v-model="qrDialog" width="500"
      ><v-card><v-img :src="qrSrc" alt="my qr code"/></v-card
    ></v-dialog>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      drawer: false,
      qrDialog: false,
      qrSrc: "",
      mics: [
        {
          icon: "mdi-book-search",
          title: "วิธีการใช้งาน",
          to: "",
          href: "https://www.younghappy.com"
        },
        {
          icon: "mdi-cloud-download",
          title: "ดาวน์โหลดแอปยังแฮปปี้",
          to: "",
          href:
            "https://yhp.page.link/?link=http://www.younghappy.com/&apn=org.younghappy.android&isi=1279819149&ibi=com.ios.younghappy.YoungHappy"
        },
        {
          icon: "mdi-qrcode-scan",
          title: "My QR Code",
          action: "generateQR"
        }
      ],
      miniVariant: false,
      right: true,
      rightDrawer: false
    };
  },
  computed: {
    getBasePath() {
      let path = "";
      try {
        path = this.$route.name.split("-")[0];
      } finally {
        return path;
      }
    },
    events() {
      return [
        {
          icon: "mdi-home",
          title: "หน้าแรก",
          to: "/event"
        },
        this.$store.state.authenticated.authenticated && {
          icon: "mdi-account-edit",
          title: "แก้ไขข้อมูลส่วนตัว",
          to: "/account/update"
        }
      ].filter(Boolean);
    },
    shop() {
      return [
        {
          icon: "mdi-cart",
          title: "รายการสินค้า",
          to: "/shop"
        },
        this.$store.state.authenticated.authenticated && {
          icon: "mdi-cart",
          title: "ประวัติการซื้อสินค้า",
          to: "/shop/transaction"
        }
      ].filter(Boolean);
    }
  },
  methods: {
    logout() {
      this.$cookies.removeAll();
      window.location.reload();
    },
    generateQr() {
      this.qrDialog = true;
      this.$QRCode.toDataURL("I am a pony!", (err, url) => {
        this.qrSrc = url;
      });
    },
    handleDrawer(item) {
      this.$store.dispatch("app/savePreviousPath", this.$route.fullPath);
      this.drawer = false;
      if (item.action && item.action === "generateQR") this.generateQr();
    }
  }
};
</script>

<style>
.app {
  font-family: "Saraban", sans-serif !important;
}
.title-ja {
  font-size: 20 sp;
  font-weight: 500;
}
.lineicons {
  /* width: 100px; */
  background: url("https://api.iconify.design/ls-line.svg") no-repeat center
    center / contain;
}
</style>
